.about-container {
  margin-top: 20px;
  margin: 0;
  padding: 20px;
}

.image {
  min-height: 250px;
  max-height: 270px;
  width: 250px;
  border-radius: 50%;
}

.board-member-name, .board-member-position {
  text-align: center;
}

@media (max-width: 768px) {
  .image {
    margin: auto; 
    float: none; 
  }
}

.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-image {
  max-width: 100%;
  height: auto;
}
