.home-container {
    height: 85vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    margin-bottom: 0px;
  }

  @media (max-width: 767px) {
    .home-container {
      height: 50vh; 
      background-size: contain; 
    }
  }

  .row1{
    padding: 20px;
  }

.vision-point {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.vision-point img {
  width: 20px;
  height: 20px;
  margin-top: 6px;
  margin-right: 10px;
}

.vision-point span {
  font-size: 16px;
  color: #333;
}