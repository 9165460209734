* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
  font-size: 18px;
}

/* navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: aliceblue;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  min-height: 70px;
  z-index: 1000;
}


/* logo  */
.logo {
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
}


/* ul  */
.navMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
}

/* media queries */

@media screen and (max-width: 780px){
  
  /* hamburger */
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .bar {
        background-color: #120f0b;
        width: 20px;
        height: 3px;
        display: block;
        margin: 5px;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
    }

  /* adding active class to the bars  */
  .hamburger.active .bar:nth-child(2){
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1){
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3){
    transform: translateY(-8px) rotate(-45deg);
  }


  /* navMenu */

  .navMenu{
    position: absolute;
    flex-direction: column;
    gap: 0;
    top: 70px;
    left: -100%;
    text-align: start;
    width: 100%;
    transition: 0.7s ease-in-out;
    background-color: aliceblue;
    padding: 10px;
    z-index: 1000;
  }

  .navMenu.active{
    left: 0;
  }

  .navMenu li {
    margin: 16px 0;
  }
  
}
.navMenu li:hover {
  color: blue;
  scale: 1.03;
}

.navMenu li {
  transition: 0.5s ease;
  font-weight: 600;
}


.maincontent{
  padding: 5px;
  background-color: rgb(238, 245, 252);
}
.content{
  margin-top: 65px;
}
body{
  background-color: rgb(238, 245, 252);
}


