.gallery-container {
  text-align: center;
}

.gallery-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.gallery-image {
  width: 100%;
  height: 250px; 
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: 0.5s ease-in-out;
}

.gallery-image:hover {
  transform: scale(1.05);
}