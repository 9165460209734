.contactbody{
  padding: 20px;
  padding-top: 10px;
}

.contact-options{
  background-color: white;
  padding: 20px;
  border-radius:20px;
  box-shadow: 0.1px 0.1px 0.1px;
}

label{
  font-weight: 600;
}

input{
  min-height: 40px;
}

#message{
  min-height: 100px;
}