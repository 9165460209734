
.footer {
  background-color: rgb(20, 136, 237);
  padding: 20px;
  border-top-right-radius: 20px;
  bottom:0;
  color: rgb(238, 236, 236);
  font-weight: 700;
}
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-left {
    flex: 1;
  }
  
  .footer-right {
    display: flex;
    align-items: center;
  }
  
  .social-media {
    display: flex;
    align-items: center;
  }
  
  .social-media p {
    margin-right: 10px;
  }
  
  .social-media a {
    display: inline-block; 
  }
  
  .social-media img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-right {
      margin-top: 20px;
    }
  
    .social-media {
      /* Keep the social media icons horizontal on smaller screens */
      flex-direction: row;
    }
  
    .social-media p {
      margin-bottom: 0;
      margin-right: 10px;
    }
  
    .social-media img {
      margin-right: 10px;
    }
  }
  